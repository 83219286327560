// generated by scripts/build_shader_chunks.js

import catmull_rom_spline from './glsl/catmull_rom_spline.glsl';
import cubic_bezier from './glsl/cubic_bezier.glsl';
import ease_back_in from './glsl/ease_back_in.glsl';
import ease_back_in_out from './glsl/ease_back_in_out.glsl';
import ease_back_out from './glsl/ease_back_out.glsl';
import ease_bezier from './glsl/ease_bezier.glsl';
import ease_bounce_in from './glsl/ease_bounce_in.glsl';
import ease_bounce_in_out from './glsl/ease_bounce_in_out.glsl';
import ease_bounce_out from './glsl/ease_bounce_out.glsl';
import ease_circ_in from './glsl/ease_circ_in.glsl';
import ease_circ_in_out from './glsl/ease_circ_in_out.glsl';
import ease_circ_out from './glsl/ease_circ_out.glsl';
import ease_cubic_in from './glsl/ease_cubic_in.glsl';
import ease_cubic_in_out from './glsl/ease_cubic_in_out.glsl';
import ease_cubic_out from './glsl/ease_cubic_out.glsl';
import ease_elastic_in from './glsl/ease_elastic_in.glsl';
import ease_elastic_in_out from './glsl/ease_elastic_in_out.glsl';
import ease_elastic_out from './glsl/ease_elastic_out.glsl';
import ease_expo_in from './glsl/ease_expo_in.glsl';
import ease_expo_in_out from './glsl/ease_expo_in_out.glsl';
import ease_expo_out from './glsl/ease_expo_out.glsl';
import ease_quad_in from './glsl/ease_quad_in.glsl';
import ease_quad_in_out from './glsl/ease_quad_in_out.glsl';
import ease_quad_out from './glsl/ease_quad_out.glsl';
import ease_quart_in from './glsl/ease_quart_in.glsl';
import ease_quart_in_out from './glsl/ease_quart_in_out.glsl';
import ease_quart_out from './glsl/ease_quart_out.glsl';
import ease_quint_in from './glsl/ease_quint_in.glsl';
import ease_quint_in_out from './glsl/ease_quint_in_out.glsl';
import ease_quint_out from './glsl/ease_quint_out.glsl';
import ease_sine_in from './glsl/ease_sine_in.glsl';
import ease_sine_in_out from './glsl/ease_sine_in_out.glsl';
import ease_sine_out from './glsl/ease_sine_out.glsl';
import quadratic_bezier from './glsl/quadratic_bezier.glsl';
import quaternion_rotation from './glsl/quaternion_rotation.glsl';
import quaternion_slerp from './glsl/quaternion_slerp.glsl';


export const ShaderChunk = {
  catmull_rom_spline: catmull_rom_spline,
  cubic_bezier: cubic_bezier,
  ease_back_in: ease_back_in,
  ease_back_in_out: ease_back_in_out,
  ease_back_out: ease_back_out,
  ease_bezier: ease_bezier,
  ease_bounce_in: ease_bounce_in,
  ease_bounce_in_out: ease_bounce_in_out,
  ease_bounce_out: ease_bounce_out,
  ease_circ_in: ease_circ_in,
  ease_circ_in_out: ease_circ_in_out,
  ease_circ_out: ease_circ_out,
  ease_cubic_in: ease_cubic_in,
  ease_cubic_in_out: ease_cubic_in_out,
  ease_cubic_out: ease_cubic_out,
  ease_elastic_in: ease_elastic_in,
  ease_elastic_in_out: ease_elastic_in_out,
  ease_elastic_out: ease_elastic_out,
  ease_expo_in: ease_expo_in,
  ease_expo_in_out: ease_expo_in_out,
  ease_expo_out: ease_expo_out,
  ease_quad_in: ease_quad_in,
  ease_quad_in_out: ease_quad_in_out,
  ease_quad_out: ease_quad_out,
  ease_quart_in: ease_quart_in,
  ease_quart_in_out: ease_quart_in_out,
  ease_quart_out: ease_quart_out,
  ease_quint_in: ease_quint_in,
  ease_quint_in_out: ease_quint_in_out,
  ease_quint_out: ease_quint_out,
  ease_sine_in: ease_sine_in,
  ease_sine_in_out: ease_sine_in_out,
  ease_sine_out: ease_sine_out,
  quadratic_bezier: quadratic_bezier,
  quaternion_rotation: quaternion_rotation,
  quaternion_slerp: quaternion_slerp,

};

